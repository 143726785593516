// import React from 'react';
// import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
// import './App.css'
//
// // Pages
// import Home from './pages'
// import About from './pages/about'
// import Team from './pages/team'
// import News from './pages/news'
// import ContactUs from './pages/contact_us'
// import NotFound from './pages/404'
//
// function App() {
// 	return (
// 		<Router>
// 			<div className="App">
// 				<Switch>
// 					<Route exact path={'/'} component={Home}/>
// 					<Route exact path={'/Home'} component={Home}/>
// 					<Route exact path={'/About'} component={About}/>
// 					<Route exact path={'/Team'} component={Team}/>
// 					<Route exact path={'/News'} component={News}/>
// 					<Route exact path={'/ContactUS'} component={ContactUs}/>
// 					<Route component={NotFound}/>
// 				</Switch>
// 			</div>
// 		</Router>
// 	);
// }
//
// export default App

/*

                                 _                             _                    _  _
  _   _  ___   ___  ___         | |  __ _  ____ _   _         | |  ___    __ _   __| |(_) _ __    __ _
 | | | |/ __| / _ \/ __|        | | / _` ||_  /| | | |        | | / _ \  / _` | / _` || || '_ \  / _` |
 | |_| |\__ \|  __/\__ \        | || (_| | / / | |_| |        | || (_) || (_| || (_| || || | | || (_| |
  \__,_||___/ \___||___/        |_| \__,_|/___| \__, |        |_| \___/  \__,_| \__,_||_||_| |_| \__, |
                                                |___/                                            |___/

To build project run "npm run build"
 */


import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {ReactComponent as LoadingScreenLogo}  from './assets/loading_letters.svg'
import {ReactComponent as BackgroundBase} from './assets/background_colorful_v3.svg'
import './App.css'

// Pages
const HomePromise = import('./pages');
const Home = lazy(() => HomePromise)
const AboutPromise = import('./pages/about');
const About = lazy(() => AboutPromise)
const TeamPromise = import('./pages/team');
const Team = lazy(() => TeamPromise)
const NewsPromise = import('./pages/news');
const News = lazy(() => NewsPromise)
const ContactUsPromise = import('./pages/contact_us');
const ContactUs = lazy(() => ContactUsPromise)
const NotFoundPromise = import('./pages/404');
const NotFound = lazy(() => NotFoundPromise)


// const stockChartPromise = import("./StockChart");
// const StockChart = lazy(() => stockChartPromise);

function App() {
	return (
		<Router>
			<div className="App">
				{/*<Suspense fallback={<LoadingScreenLogo/>}>*/}
				<Suspense fallback={<BackgroundBase className={"PageBackgroundBase"}/>}>
					<Switch>
						<Route exact path={'/'} component={Home}/>
						<Route exact path={'/Home'} component={Home}/>
						<Route exact path={'/About'} component={About}/>
						<Route exact path={'/Team'} component={Team}/>
						<Route exact path={'/News'} component={News}/>
						<Route exact path={'/ContactUS'} component={ContactUs}/>
						<Route component={NotFound}/>
					</Switch>
				</Suspense>
			</div>
		</Router>
	);
}

export default App

